export interface IScrollTracks {
  [key: string]: number;
}

export interface ITableProps {
  title: string;
  dataIndex?: string;
  key?: string;
  width?: number;
  fixed?: string;
  filters?: { text: string; value: string }[];
  onFilter?: (value: string, record: string) => boolean;
  children?: ITableProps[];
}

export enum Order {
  DESC = "DESC",
  ASC = "ASC"
}
