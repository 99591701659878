import { NAVI_AC_PAPERS } from "@/graphql/navi.graphql";
import {
  GET_DISPLAY_PREFERENCE,
  PUBMED_ADD_HISTORY,
  PUBMED_ALL,
  PUBMED_FETCH,
  PUBMED_PARSE_DOI,
  PUBMED_SEARCH_HISTORY,
  UPDATE_DISPLAY_PREFERENCE,
} from "@/graphql/pubmed.graphql";
import {
  DisplayHelper,
  DisplayPreference,
  FetchParams,
  IPubmedALL,
  IPubmedHistory,
  PubmedSearch,
  RecentHistory,
  SearchContentListItem,
  SearchParams,
} from "@/interfaces/pubmed.interface";
import { ITranslatorYoudao } from "@/interfaces/translator.interface";
import { currentUserState, loadingState } from "@/stores/common";
import {
  pubmedAcState,
  pubmedMergeFieldState,
  pubmedReplaceFieldState,
  pubmedState,
} from "@/stores/pubmed";
import { isClient } from "@/utils/common.util";
import { parseFetchParams } from "@/utils/pubmed.util";
import { shortUUID } from "@/utils/string.util";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { statsSearch } from "./useMatomo";

export const usePubmedState = () => {
  const [searchParams, setSearchParams] = useRecoilState<Partial<SearchParams>>(
    pubmedMergeFieldState("searchParams")
  );
  const [fetchParams, setFetchParams] = useRecoilState<Partial<FetchParams>>(
    pubmedMergeFieldState("fetchParams")
  );
  const [displayHelper, setDisplayHelper] = useRecoilState<
    Partial<DisplayHelper>
  >(pubmedMergeFieldState("displayHelper"));
  const [fetchResults, setFetchResults] = useRecoilState<
    SearchContentListItem[]
  >(pubmedReplaceFieldState("fetchResults"));

  const [translation, setTranslation] = useRecoilState<
    Record<string, ITranslatorYoudao>
  >(pubmedMergeFieldState("translation"));

  const [translator, setTranslator] = useRecoilState<
    Partial<Record<string, boolean>>
  >(pubmedMergeFieldState("translator"));

  const [id, setId] = useRecoilState<string>(pubmedReplaceFieldState("id"));

  const [displayPreference, setDisplayPreference] = useRecoilState<
    Partial<DisplayPreference>
  >(pubmedMergeFieldState("displayPreference"));

  const [recentHistory, setRecentHistory] = useRecoilState<RecentHistory[]>(
    pubmedReplaceFieldState("recentHistory")
  );

  return {
    setSearchParams,
    setFetchParams,
    setDisplayHelper,
    setFetchResults,
    setId,
    setRecentHistory,
    setDisplayPreference,
    setTranslation,
    setTranslator,
  };
};

export const usePubmedSearch = () => {
  const { setSearchParams } = usePubmedState();

  const router = useRouter();

  const { searchParams } = useRecoilValue(pubmedState);
  const updateTerm = (term: string) => setSearchParams({ term });

  const [pubmedHistory, { data, loading }] = useMutation<
    { addPubmedHistory: { id: string } },
    {
      addPubmedHistoryDto: { eSearch: SearchParams };
    }
  >(PUBMED_ADD_HISTORY);

  const setLoadingState = useSetRecoilState(loadingState);

  const handleSearch = async (term: string) => {
    updateTerm(term);
    setLoadingState({ loading: true });
    await pubmedHistory({
      variables: {
        addPubmedHistoryDto: {
          eSearch: { ...searchParams, term },
        },
      },
    });
  };

  const handleFilter = async (_searchParams: Partial<SearchParams>) => {
    setLoadingState({ loading: true });
    const { data } = await pubmedHistory({
      variables: {
        addPubmedHistoryDto: {
          eSearch: { ...searchParams, ..._searchParams },
        },
      },
    });
    if (data) {
      router.push(
        "/explore/pubmed/[id]",
        `/explore/pubmed/${shortUUID(data.addPubmedHistory.id)}`
      );
    }
  };

  useEffect(() => {
    if (data) {
      router.push(
        "/explore/pubmed/[id]",
        `/explore/pubmed/${shortUUID(data.addPubmedHistory.id)}`
      );
    }
  }, [data]);

  return {
    loading,
    handleSearch,
    updateTerm,
    handleFilter,
  };
};

export const usePubmedFetch = () => {
  const {
    setFetchParams,
    setFetchResults,
    setDisplayHelper,
  } = usePubmedState();

  const { fetchParams: sourceFetchParams, displayHelper } = useRecoilValue(
    pubmedState
  );

  const router = useRouter();

  const { page, size } = router.query;

  useEffect(() => {
    setDisplayHelper({
      page: parseInt(page as string, 10) || 1,
      size: parseInt(size as string, 10) || 20,
    });
  }, [page, size]);

  // fetch query
  const [pubmedFetch, { data, loading }] = useLazyQuery<
    {
      pubmedSearch: Pick<PubmedSearch, "papers">;
    },
    { eFetch: Partial<FetchParams> }
  >(PUBMED_FETCH);

  // set fetch results
  useMemo(() => {
    if (data) {
      setFetchResults(data?.pubmedSearch?.papers);
    }
  }, [data]);

  // hanlde fetch
  const handleFetch = async ({
    page: _page,
    size: _size,
  }: {
    page?: number;
    size?: number;
  }) => {
    const page = _page ?? (parseInt(router.query.page as string, 10) || 1);
    const size = _size ?? (parseInt(router.query.size as string, 10) || 20);
    await changePage({ page, size });
    const newFetchparams = parseFetchParams({ page, size });
    setFetchParams(newFetchparams);
    pubmedFetch({
      variables: { eFetch: { ...sourceFetchParams, ...newFetchparams } },
    });
  };

  // change page
  const changePage = async ({
    page,
    size,
  }: {
    page?: number;
    size?: number;
  }) => {
    setDisplayHelper({ page, size });
    router.push(
      `${router.pathname}?page=${page}&size=${size}`,
      `${router.pathname}?page=${page}&size=${size}`.replace(
        "[id]",
        router.query.id as string
      )
    );
  };

  return {
    pubmedFetch,
    loading,
    handleFetch,
  };
};

export const usePubmedSearchHistory = () => {
  const currentUser = useRecoilValue(currentUserState);
  const { setRecentHistory } = usePubmedState();

  const { data, loading } = useQuery<{ getMyPubmedHistories: IPubmedHistory }>(
    PUBMED_SEARCH_HISTORY,
    {
      skip: !currentUser.id,
    }
  );

  useMemo(() => {
    if (data) {
      const { results } = data?.getMyPubmedHistories;
      setRecentHistory(results);
    }
  }, [data]);

  return loading;
};

export const usePubmedServer = ({
  page,
  size,
  fetchParams,
  id,
}: {
  page?: number;
  size?: number;
  fetchParams?: { retmax: number; retstart: number };
  id?: string;
}) => {
  const {
    setId,
    setSearchParams,
    setFetchParams,
    setFetchResults,
    setDisplayHelper,
  } = usePubmedState();

  const currentUser = useRecoilValue(currentUserState);

  const { id: prevId } = useRecoilValue(pubmedState);

  useMemo(() => {
    if (page) setDisplayHelper({ page });
  }, [page]);

  useMemo(() => {
    if (size) setDisplayHelper({ size });
  }, [size]);

  const { data, loading } = useQuery<
    { pubmedAll: IPubmedALL },
    { id: string; eSearch: Partial<SearchParams>; userId: string }
  >(PUBMED_ALL, {
    variables: {
      eSearch: fetchParams,
      id,
      userId: currentUser.id,
    },
    skip: isClient && prevId === id,
  });

  useMemo(() => {
    if (data) {
      const { id, pubmedSearch, ...params } = data?.pubmedAll;
      setId(id);
      setSearchParams(params);
      const { papers, count, querytranslation, ...fetchParams } = pubmedSearch;
      setFetchParams(fetchParams);
      setDisplayHelper({ count, querytranslation });
      setFetchResults(papers);
    }
  }, [data]);

  const { searchParams, displayHelper } = useRecoilValue(pubmedState);

  const { term, count } = useMemo(() => {
    return {
      count: displayHelper?.count,
      term: searchParams?.term,
    };
  }, [searchParams, displayHelper]);

  useEffect(() => {
    if (term && count) {
      statsSearch(term, count, "PUBMED");
    }
  }, [term, count]);

  return { loading };
};

export const usePubmedLazyServer = ({
  page,
  size,
  fetchParams,
  id,
}: {
  page?: number;
  size?: number;
  fetchParams?: { retmax: number; retstart: number; [key: string]: any };
  id?: string;
}) => {
  const {
    setId,
    setSearchParams,
    setFetchParams,
    setFetchResults,
    setDisplayHelper,
  } = usePubmedState();

  const { displayHelper, searchParams, id: prevId } = useRecoilValue(
    pubmedState
  );

  useMemo(() => {
    if (page) setDisplayHelper({ page });
  }, [page]);

  useMemo(() => {
    if (size) setDisplayHelper({ size });
  }, [size]);

  const [search, { data, loading }] = useLazyQuery<
    { pubmedAll: IPubmedALL },
    { id: string; eSearch: Partial<SearchParams> }
  >(PUBMED_ALL, {
    variables: {
      eSearch: fetchParams,
      id: id || prevId,
    },
  });

  useMemo(() => {
    if (data) {
      const { id, pubmedSearch, ...params } = data?.pubmedAll;
      setId(id);
      setSearchParams(params);
      const { papers, count, querytranslation, ...fetchParams } = pubmedSearch;
      setFetchParams(fetchParams);
      setDisplayHelper({ count, querytranslation });
      setFetchResults(papers);
    }
  }, [data]);

  const { term, count } = useMemo(() => {
    return {
      count: displayHelper?.count,
      term: searchParams?.term,
    };
  }, [searchParams, displayHelper]);

  useEffect(() => {
    if (term && count) {
      statsSearch(term, count, "PUBMED");
    }
  }, [term, count]);

  return { search, loading };
};

export const usePubmedAC = () => {
  const [st, setSuggestTerms] = useRecoilState<string[]>(pubmedAcState);
  const { recentHistory } = useRecoilValue(pubmedState);

  const [suggest, { data }] = useLazyQuery<
    { acPapers: string[] },
    { keyword: string }
  >(NAVI_AC_PAPERS);

  useMemo(() => {
    if (data) {
      setSuggestTerms(data.acPapers);
    }
  }, [data]);

  const suggestTerms = _.debounce((keyword: string) => {
    if (keyword?.trim()) {
      suggest({ variables: { keyword: keyword?.trim() } });
    } else {
      setSuggestTerms(recentHistory?.map((r) => r?.params?.term));
    }
  }, 500);

  const suggestedTerms = useMemo(() => {
    if (st?.length === 0) {
      return [
        ...new Set(recentHistory?.map((r) => r?.params?.term)),
      ]?.map((s) => ({ value: s }));
    }
    return [...new Set(st)].map((s) => ({ value: s }));
  }, [st, recentHistory]);

  return {
    suggestTerms,
    suggestedTerms,
  };
};

export const usePubmedParseDoi = () => {
  const [_parseDoi, { data, loading }] = useLazyQuery<
    { parseDoi: string[] },
    { doi: string }
  >(PUBMED_PARSE_DOI);

  const pmids = useMemo(() => {
    if (data) {
      return data?.parseDoi?.map((s) => ({ pmid: s }));
    }
    return [];
  }, [data]);

  const parseDoi = _.debounce((doi: string) => {
    if (/^10.\d{4,9}\/[-._;()/:A-Z0-9]+$/i.test(doi)) {
      _parseDoi({ variables: { doi } });
      statsSearch(doi, 1, "search_by_doi");
    }
  }, 750);

  return {
    parseDoi,
    pmids,
    loading,
  };
};

export const useGetDisplayPreference = () => {
  const currentUser = useRecoilValue(currentUserState);

  const { setDisplayPreference } = usePubmedState();

  const { data } = useQuery<{ getPreference: { display: DisplayPreference } }>(
    GET_DISPLAY_PREFERENCE,
    {
      skip: !currentUser?.id,
    }
  );

  useMemo(() => {
    if (data) {
      setDisplayPreference(data?.getPreference?.display);
    }
  }, [data]);
};

export const useSetDisplayPreference = () => {
  const { setDisplayPreference } = usePubmedState();
  const { displayPreference } = useRecoilValue(pubmedState);
  const currentUser = useRecoilValue(currentUserState);

  const [mutatePreference, { loading }] = useMutation<
    { updateDisplayPreference: { display: DisplayPreference } },
    { update: DisplayPreference }
  >(UPDATE_DISPLAY_PREFERENCE);

  const updatePreference = async (
    p: Partial<DisplayPreference>,
    temp?: boolean
  ) => {
    if (currentUser?.id && !temp) {
      const { data } = await mutatePreference({
        variables: {
          update: { ...displayPreference, ...p },
        },
      });
      if (data) {
        setDisplayPreference(data?.updateDisplayPreference?.display);
      }
    } else {
      setDisplayPreference(p);
    }
  };
  return { updatePreference, loading };
};
