import { gql } from "apollo-boost";

export const NAVI_AC_PAPERS = gql`
  query($keyword: String!) {
    acPapers(keyword: $keyword)
  }
`;

export const NAVI_ADD_HISTORY = gql`
  mutation($search: SearchPapersDto!) {
    addNaviSearch(searchPapersDto: $search) {
      id
    }
  }
`;

export const NAVI_PAPERS = gql`
  query($id: String!, $page: Int, $size: Int) {
    naviPapers(id: $id, page: $page, size: $size) {
      results {
        content {
          articleTitle
          authors {
            lastName
            foreName
            initials
            affiliationInfo
          }
          keywords
          abstract {
            text
            label
          }
          journalInfo {
            issn
            volume
            issn
            pubdate
            title
            issoAbbrTitle
          }
          pagination
          eDoi
          ePmid
          language
          articleDate
          medlineJournalInfo {
            country
            medlineTA
            nlmid
            issn
          }
          pubHistory {
            status
            date
          }
          publicationTypes
          articleIds {
            idType
            id
          }
        }
        journal {
          id
          title
          nImpactFactor
          publisherName
          country
          categories
          meshes {
            name
          }
        }
      }
      count
    }
  }
`;

export const NAVI_HISTORY = gql`
  query($take: Int, $skip: Int) {
    getMyNaviHistories(skip: $skip, take: $take) {
      results {
        params {
          term
        }
        updated_at
        id
      }
      count
    }
  }
`;

export const NAVI_GET_HISTORY = gql`
  query($id: String!) {
    getNaviHistory(id: $id) {
      id
      updated_at
      params {
        term
        ifFilter
        ifFilterMin
        ifFilterMax
        sort
        mindate
        maxdate
        articleTypeFilterList
        page
        size
      }
    }
  }
`;

export const NAVI_INSPECT_PAPERS = gql`
  query($id: String!) {
    inspectPapers(id: $id) {
      id
      searchParams {
        term
        ifFilter
        ifFilterMin
        ifFilterMax
        sort
        mindate
        maxdate
        articleTypeFilterList
        page
        size
      }
      took
      count
      naviAggsImpactFactorQuantiles {
        key
        value
      }
      naviAggsTopLocations {
        key
        score
        count
        papers {
          paperContents {
            id
            content {
              articleTitle
              authors {
                lastName
                foreName
                initials
                affiliationInfo
              }
              keywords
              abstract {
                text
                label
              }
              journalInfo {
                issn
                volume
                issn
                pubdate
                title
                issoAbbrTitle
              }
              pagination
              eDoi
              ePmid
              language
              articleDate
              medlineJournalInfo {
                country
                medlineTA
                nlmid
                issn
              }
              pubHistory {
                status
                date
              }
              publicationTypes
              articleIds {
                idType
                id
              }
            }
            journal {
              id
              title
              nImpactFactor
              publisherName
              country
              categories
              meshes {
                name
              }
            }
          }
        }
      }
      naviAggsTopAffiliations {
        key
        score
        count
        papers {
          paperContents {
            id
            content {
              articleTitle
              authors {
                lastName
                foreName
                initials
                affiliationInfo
              }
              keywords
              abstract {
                text
                label
              }
              journalInfo {
                issn
                volume
                issn
                pubdate
                title
                issoAbbrTitle
              }
              pagination
              eDoi
              ePmid
              language
              articleDate
              medlineJournalInfo {
                country
                medlineTA
                nlmid
                issn
              }
              pubHistory {
                status
                date
              }
              publicationTypes
              articleIds {
                idType
                id
              }
            }
            journal {
              id
              title
              nImpactFactor
              publisherName
              country
              categories
              meshes {
                name
              }
            }
          }
        }
      }
      naviAggsTopAuthors {
        key
        score
        count
        papers {
          paperContents {
            id
            content {
              articleTitle
              authors {
                lastName
                foreName
                initials
                affiliationInfo
              }
              keywords
              abstract {
                text
                label
              }
              journalInfo {
                issn
                volume
                issn
                pubdate
                title
                issoAbbrTitle
              }
              pagination
              eDoi
              ePmid
              language
              articleDate
              medlineJournalInfo {
                country
                medlineTA
                nlmid
                issn
              }
              pubHistory {
                status
                date
              }
              publicationTypes
              articleIds {
                idType
                id
              }
            }
            journal {
              id
              title
              nImpactFactor
              publisherName
              country
              categories
              meshes {
                name
              }
            }
          }
        }
      }
      naviAggsTopJournals {
        from
        to
        count
        journals {
          title
          count
          papers {
            paperContents {
              id
              content {
                articleTitle
                authors {
                  lastName
                  foreName
                  initials
                  affiliationInfo
                }
                keywords
                abstract {
                  text
                  label
                }
                journalInfo {
                  issn
                  volume
                  issn
                  pubdate
                  title
                  issoAbbrTitle
                }
                pagination
                eDoi
                ePmid
                language
                articleDate
                medlineJournalInfo {
                  country
                  medlineTA
                  nlmid
                  issn
                }
                pubHistory {
                  status
                  date
                }
                publicationTypes
                articleIds {
                  idType
                  id
                }
              }
              journal {
                id
                title
                nImpactFactor
                publisherName
                country
                categories
                meshes {
                  name
                }
              }
            }
          }
        }
      }
      naviAggsDateImpactFactor {
        year
        count
        ratio {
          from
          to
          count
        }
      }
      naviAggsSignificantKeywords {
        keyword
        count
        score
      }
    }
  }
`;

export const NAVI_SUBSCRIBE = gql`
  mutation($add: AddSubscribeDto!) {
    addSubscriber(addSubscribeDto: $add)
  }
`;

export const NAVI_PARSE_TITLE = gql`
  query($term: String!) {
    searchPapers(searchPapersDto: { term: $term }) {
      results {
        id
      }
    }
  }
`;

export const NAVI_TRENDING = gql`
  query($page: Int, $size: Int) {
    naviTrending(page: $page, size: $size) {
      count
      results {
        id
        content {
          articleTitle
          authors {
            lastName
            foreName
            initials
            affiliationInfo
          }
          keywords
          abstract {
            text
            label
          }
          journalInfo {
            issn
            volume
            issn
            pubdate
            title
            issoAbbrTitle
          }
          pagination
          eDoi
          ePmid
          language
          articleDate
          medlineJournalInfo {
            country
            medlineTA
            nlmid
            issn
          }
          pubHistory {
            status
            date
          }
          publicationTypes
          articleIds {
            idType
            id
          }
        }
        journal {
          id
          title
          nImpactFactor
          publisherName
          country
          categories
          meshes {
            name
          }
        }
      }
    }
  }
`;
