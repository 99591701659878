import { copy } from "@/utils/common.util";
import {
  ContactsOutlined,
  DatabaseOutlined,
  ForkOutlined,
  GroupOutlined,
  HomeOutlined,
  MessageOutlined,
  SearchOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ShortTextIcon from "@material-ui/icons/ShortText";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import {
  IconBundle,
  IconComment,
  IconImpact,
  IconP,
  IconSearch,
} from "../IconFont";
import styles from "./styles.module.less";

interface IProps {
  title: string;
  description: string;
  url: string;
  icon: string;
  shortUrl?: string;
  size?: "small" | "large";
}

const headerIconMap = (className: string, key: string) =>
  ({
    home: <HomeOutlined className={className} />,
    explore: <SearchOutlined className={className} />,
    bundle: <GroupOutlined className={className} />,
    about: <ForkOutlined className={className} />,
    pubmed: <IconP className={className} />,
    journal: <IconImpact className={className} />,
    navi: <IconSearch className={className} />,
    subscription: <IconBundle className={className} />,
    chat: <MessageOutlined className={className} />,
    feedback: <ContactsOutlined className={className} />,
    directory: <DatabaseOutlined className={className} />,
    collection: <IconComment className={className} />,
    docs: <SnippetsOutlined className={className} />,
  }[key]);

const RouteCard: React.FC<IProps> = ({
  title,
  description,
  shortUrl,
  url,
  icon,
  size = "large",
}) => {
  const router = useRouter();
  return (
    <Card className={styles.box} elevation={0}>
      <CardContent>
        <div onClick={() => router.push(url)} style={{ marginBottom: "auto" }}>
          <Typography
            gutterBottom
            variant={size === "large" ? "h5" : "h6"}
            component="div"
            style={{ marginBottom: "1.5rem", marginTop: "0.5rem" }}
          >
            <div className={styles.flex}>
              {headerIconMap(styles.icon, icon)}
              <Link href={url}>
                <a className={styles.href}>{title}</a>
              </Link>
            </div>
          </Typography>
          <Typography variant="body1" color="textSecondary" component="div">
            {description}
          </Typography>
        </div>
        {shortUrl && (
          <Typography
            variant="body2"
            color="textSecondary"
            component="div"
            className={styles.boxer}
            onClick={() => copy(shortUrl)}
          >
            <div className={styles.flex}>
              <ShortTextIcon />
              <div className={styles.url}>{shortUrl}</div>
            </div>
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default RouteCard;
