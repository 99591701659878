import { ITranslatorYoudao } from "./translator.interface";

export enum SearchSortType {
  JOURNAL = "JOURNAL",
  PUB_DATE = "PUB_DATE",
  RELEVANCE = "RELEVANCE",
  TITLE = "TITLE",
  AUTHOR = "AUTHOR",
  MOST_RECENT = "MOST_RECENT",
}

export enum SearchDateType {
  CTDT = "CTDT",
  EDAT = "EDAT",
  PDAT = "PDAT",
  MHDA = "MHDA",
}

export enum JournalKeywordField {
  NLMID = "NLMID",
  ISSN = "ISSN",
}

export enum SearchArticleType {
  CLINICAL_STUDY = "CLINICAL_STUDY",
  REVIEW = "REVIEW",
  CASE_REPORTS = "CASE_REPORTS",
  META_ANALYSIS = "META_ANALYSIS",
  GUIDELINE = "GUIDELINE",
  MULTICENTER_STUDY = "MULTICENTER_STUDY",
  RANDOMIZED_CONTROLLED_TRIAL = "RANDOMIZED_CONTROLLED_TRIAL",
}

export interface SearchParams {
  term: string;
  datetype?: SearchDateType;
  mindate?: string;
  maxdate?: string;
  sort: SearchSortType;
  ifFilter: boolean;
  dateFilter: boolean;
  ifFilterMin: number;
  ifFilterMax: number;
  articleTypeFilterList: Array<SearchArticleType>;
  retmax?: number;
  retstart?: number;
}

export interface RecentHistory {
  id: string;
  params: SearchParams;
}

export interface FetchParams {
  retstart: number;
  retmax: number;
  webenv: string;
  query_key: number;
}

export interface SearchContentListItem {
  articleTitle: string;
  pagination: string;
  authors: Array<{
    lastName: string;
    foreName: string;
    initials: string;
    affiliationInfo: string[];
  }>;
  keywords: string[];
  abstract: Array<{ text: string; label: string }>;
  journalInfo: {
    issn: string;
    volume: string;
    pubdate: string;
    title: string;
    issoAbbrTitle: string;
  };
  eDoi: string;
  ePmid: string;
  language: string;
  articleDate: string;
  medlineJournalInfo: {
    country: string;
    medlineTA: string;
    nlmid: string;
    issn: string;
  };
  pubHistory: Array<{ status: string; date: string }>;
  publicationTypes: string[];
  articleIds: Array<{ idType: string; id: string }>;
  journal: {
    id: string;
    title: string;
    nImpactFactor: number;
    publisherName: string;
    country: string;
    categories: string;
    meshes: { name: string }[];
  };
}

export interface DisplayPreference {
  verbose: boolean;
  defaultActiveKey: string;
  immersiveMode: boolean;
  size?: number;
}

export interface DisplayHelper {
  error: boolean;
  count: number;
  querytranslation?: string;
  correctedQuery: string;
  page: number;
  size: number;
}

export interface SearchState {
  id: string;
  searchParams: SearchParams;
  fetchParams: FetchParams;
  recentHistory: RecentHistory[];
  fetchResults: SearchContentListItem[];
  displayHelper: DisplayHelper;
  displayPreference: DisplayPreference;
  suggestTerms: string[];
  translator: Record<string, boolean>;
  translation: Record<string, ITranslatorYoudao>;
}

export interface PubmedSearch extends FetchParams {
  papers: SearchContentListItem[];
  querytranslation: string;
  count: number;
}

export interface IPubmedALL extends SearchParams {
  id: string;
  pubmedSearch: PubmedSearch;
}

export interface IPubmedHistory {
  count: number;
  results: { id: string; params: SearchParams; updated_at: string }[];
}
