import { defaultNaviParams } from "@/helpers/defaultValue";
import { NaviInspection, NaviState } from "@/interfaces/navi.interface";
import { SearchContentListItem } from "@/interfaces/pubmed.interface";
import _ from "lodash";
import { atom, RecoilState, selector, selectorFamily } from "recoil";
import { v4 as uuidv4 } from "uuid";

export const defaultNaviState: NaviState = {
  id: uuidv4(),
  searchParams: defaultNaviParams,
  recentHistory: [],
  fetchResults: [],
  displayHelper: { count: 0 },
  suggestTerms: [],
};

export const naviInspectState: RecoilState<Partial<NaviInspection>> = atom({
  key: "naviInspection",
  default: { searchParams: {} },
});

export const naviAcState: RecoilState<string[]> = atom({
  key: "naviAc",
  default: [],
});

export const naviState: RecoilState<NaviState> = atom({
  key: "navi",
  default: defaultNaviState,
});

type NaviMergeField = "searchParams" | "displayHelper" | "displayPreference";

export const naviMergedField = selectorFamily<any, NaviMergeField>({
  key: "naviMergedField",
  get: (field: NaviMergeField) => ({ get }) => get(naviState)[field],
  set: (field: NaviMergeField) => ({ set }, newValue) =>
    set(naviState, (prevState) => {
      // @ts-ignore
      const { __typename, ...others } = newValue;
      return {
        ...prevState,
        [field]: { ...prevState[field], ...others },
      };
    }),
});

export const naviReplaceField = selectorFamily<any, keyof NaviState>({
  key: "naviReplaceField",
  get: (field: keyof NaviState) => ({ get }) => get(naviState)[field],
  set: (field: keyof NaviState) => (
    { set },
    newValue: NaviState[typeof field]
  ) => set(naviState, (prevState) => ({ ...prevState, [field]: newValue })),
});

export const naviComputedFieldState = selector({
  key: "naviComputedField",
  get: ({ get }) => {
    return {
      latest10HistoryTerms: _.uniqBy(
        get(naviState)
          ?.recentHistory?.map(({ params }) => params?.term)
          .filter((e) => e)
          .map((term) => ({ value: term! }))
          .reverse(),
        "value"
      ).slice(0, 10),
      filteredHistory: get(naviState)?.recentHistory?.filter(
        (e) => e?.params?.term
      ),
    };
  },
});

export const naviTrendingState: RecoilState<SearchContentListItem[]> = atom({
  key: "naviTrending",
  default: [],
});
