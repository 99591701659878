import {
  NAVI_AC_PAPERS,
  NAVI_ADD_HISTORY,
  NAVI_GET_HISTORY,
  NAVI_HISTORY,
  NAVI_INSPECT_PAPERS,
  NAVI_PAPERS,
  NAVI_PARSE_TITLE,
  NAVI_TRENDING,
} from "@/graphql/navi.graphql";
import {
  DisplayHelper,
  NaviInspection,
  NaviParams,
  RecentHistory,
} from "@/interfaces/navi.interface";
import { SearchContentListItem } from "@/interfaces/pubmed.interface";
import { currentUserState } from "@/stores/common";
import {
  naviAcState,
  naviInspectState,
  naviMergedField,
  naviReplaceField,
  naviState,
  naviTrendingState,
} from "@/stores/navi";
import { shortUUID } from "@/utils/string.util";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { statsSearch } from "./useMatomo";

export const useNaviState = () => {
  const [searchParams, setSearchParams] = useRecoilState<Partial<NaviParams>>(
    naviMergedField("searchParams")
  );
  const [displayHelper, setDisplayHelper] = useRecoilState<
    Partial<DisplayHelper>
  >(naviMergedField("displayHelper"));
  const [fetchResults, setFetchResults] = useRecoilState<
    SearchContentListItem[]
  >(naviReplaceField("fetchResults"));

  const [id, setId] = useRecoilState<string>(naviReplaceField("id"));

  const [recentHistory, setRecentHistory] = useRecoilState<RecentHistory[]>(
    naviReplaceField("recentHistory")
  );

  return {
    setSearchParams,
    setDisplayHelper,
    setFetchResults,
    setId,
    setRecentHistory,
  };
};

export const useNaviServer = ({
  page,
  size,
  id,
}: {
  page: number;
  size: number;
  id: string;
}) => {
  const {
    setSearchParams,
    setId,
    setFetchResults,
    setDisplayHelper,
  } = useNaviState();

  const { id: prevId, searchParams } = useRecoilValue(naviState);

  useMemo(() => {
    if (page) {
      setSearchParams({ page });
    }
  }, [page]);

  useMemo(() => {
    if (size) {
      setSearchParams({ size });
    }
  }, [size]);

  const { data: naviHistory } = useQuery<
    { getNaviHistory: { id: string; params: NaviParams } },
    { id: string }
  >(NAVI_GET_HISTORY, { variables: { id }, skip: prevId == id });

  useMemo(() => {
    if (naviHistory) {
      setId(id);
      setSearchParams(naviHistory?.getNaviHistory?.params);
    }
  }, [naviHistory]);

  const { data, loading } = useQuery<
    {
      naviPapers: {
        results: {
          content: SearchContentListItem;
          journal: SearchContentListItem["journal"];
        }[];
        count: number;
      };
    },
    { id: string; page?: number; size?: number }
  >(NAVI_PAPERS, {
    variables: { id, page, size },
  });

  useMemo(() => {
    if (data) {
      setDisplayHelper({ count: data?.naviPapers?.count });
      setFetchResults(
        data?.naviPapers?.results?.map((r) => ({
          ...r?.content,
          journal: r?.journal,
        }))
      );
    }
  }, [data]);

  const { displayHelper } = useRecoilValue(naviState);

  const { term, count } = useMemo(() => {
    return {
      count: displayHelper?.count,
      term: searchParams?.term,
    };
  }, [searchParams, displayHelper]);

  useEffect(() => {
    if (term && count) {
      statsSearch(term, count, "NAVI");
    }
  }, [term, count]);

  return {
    loading,
  };
};

export const useNaviPaper = () => {
  const { setSearchParams, setId } = useNaviState();
  const router = useRouter();
  const { searchParams } = useRecoilValue(naviState);
  const updateTerm = (term: string) => setSearchParams({ term });

  const [naviHistory, { loading: loadingSearch }] = useMutation<
    { addNaviSearch: { id: string } },
    { search: NaviParams }
  >(NAVI_ADD_HISTORY);

  const handleSearch = async (term: string) => {
    updateTerm(term);
    const {
      data: { addNaviSearch },
    } = await naviHistory({
      variables: {
        search: { ...searchParams, term },
      },
    });
    if (addNaviSearch?.id) {
      setId(addNaviSearch?.id);
      router.push(
        "/explore/navi/[id]",
        `/explore/navi/${shortUUID(addNaviSearch.id)}`
      );
    }
  };

  const handleFilter = async (_searchParams: Partial<NaviParams>) => {
    const {
      data: { addNaviSearch },
    } = await naviHistory({
      variables: {
        search: { ...searchParams, ..._searchParams },
      },
    });
    if (addNaviSearch?.id) {
      setId(addNaviSearch?.id);
      router.push(
        "/explore/navi/[id]",
        `/explore/navi/${shortUUID(addNaviSearch.id)}`
      );
    }
  };

  const handleFetch = ({
    page = searchParams.page,
    size = searchParams.size,
  }: {
    page?: number;
    size?: number;
  }) => {
    changePage({ page, size });
  };

  const changePage = async ({
    page,
    size,
  }: {
    page?: number;
    size?: number;
  }) => {
    await router.push(
      `${router.pathname}?page=${page}&size=${size}`,
      `${router.pathname}?page=${page}&size=${size}`.replace(
        "[id]",
        router.query.id as string
      )
    );
  };

  return {
    handleSearch,
    handleFetch,
    loadingSearch,
    handleFilter,
  };
};

export const useNaviHistory = () => {
  const currentUser = useRecoilValue(currentUserState);
  const { data } = useQuery<
    {
      getMyNaviHistories: {
        results: { params: NaviParams; updated_at: string; id: string }[];
        count: number;
      };
    },
    { take?: number; skip?: number }
  >(NAVI_HISTORY, { skip: !currentUser.id });
  const { setRecentHistory } = useNaviState();

  useMemo(() => {
    if (data) {
      setRecentHistory(data?.getMyNaviHistories.results);
    }
  }, [data]);
};

export const useNaviAc = () => {
  const [st, setSuggestTerms] = useRecoilState<string[]>(naviAcState);
  const { recentHistory } = useRecoilValue(naviState);
  const historyTerms = useMemo(() => {
    return recentHistory?.map((h) => h?.params?.term);
  }, [recentHistory]);
  const [suggest, { data }] = useLazyQuery<
    { acPapers: string[] },
    { keyword: string }
  >(NAVI_AC_PAPERS);

  useMemo(() => {
    if (data) {
      setSuggestTerms(data.acPapers);
    }
  }, [data]);

  const _suggest = _.debounce(
    (keyword: string) => suggest({ variables: { keyword: keyword?.trim() } }),
    500
  );

  const suggestTerms = (keyword: string) => {
    if (keyword?.includes("`")) {
      const reg = /^`(?<target>(\w|\s)+)(`)?(\{)?((author|location|journal|title|affiliations|keywords|abstract|journals|all))*(?<end>\})?$/;
      const terms = keyword
        .split(/(\})(?=\s)/)
        .reduce((a, b) => {
          if (b === "}") {
            return [...a.slice(0, a.length - 1), `${a[a.length - 1]}}`];
          }
          return [...a, b?.trim()];
        }, [])
        .map((k) => {
          if (k.includes("`")) {
            if (reg.test(k)) {
              const e = reg.exec(k);
              const target = e?.groups?.target;
              const end = e?.groups?.end;
              if (end) {
                return [k];
              }
              return [
                "author",
                "location",
                "title",
                "affiliations",
                "keywords",
                "abstract",
                "journals",
                "journal",
                "all",
              ].map((e) => `\`${target}\`{${e}}`);
            } else {
              return [k];
            }
          } else {
            return [k];
          }
        });
      const _terms: string[] = terms
        .reduce((prevTerm, term) => {
          return term.map((t) => prevTerm.join(" ") + " " + t);
        }, [])
        .map((t) => t.trim());
      setSuggestTerms(_terms);
    } else if (keyword?.trim()) {
      _suggest(keyword?.trim());
    } else if (!keyword?.trim()) {
      setSuggestTerms(historyTerms);
    }
  };

  const suggestedTerms = useMemo(() => {
    if (st?.length === 0) {
      return [...new Set(historyTerms)].map((s) => ({ value: s }));
    }
    return [...new Set(st)].map((s) => ({ value: s }));
  }, [st, historyTerms]);

  return {
    suggestTerms,
    suggestedTerms,
  };
};

export const useNaviInspect = (id: string) => {
  const { data, loading } = useQuery<
    { inspectPapers: NaviInspection },
    { id: string }
  >(NAVI_INSPECT_PAPERS, {
    variables: { id },
  });

  const [naviInspection, setNaviInspection] = useRecoilState(naviInspectState);

  useMemo(() => {
    if (data) {
      setNaviInspection(data?.inspectPapers);
    }
  }, [data]);

  return {
    loading,
    naviInspection,
  };
};

export const useNaviParseTitle = () => {
  const [_parseTitle, { data, loading }] = useLazyQuery<
    { searchPapers: { results: { id: string }[] } },
    { term: string }
  >(NAVI_PARSE_TITLE);

  const pmids = useMemo(() => {
    if (data) {
      const {
        searchPapers: { results },
      } = data;
      if (results?.length > 0) {
        return results?.map((result) => ({ pmid: result.id }));
      }
    }
    return [];
  }, [data]);

  const parseTitle = _.debounce((title: string) => {
    _parseTitle({ variables: { term: title } });
    statsSearch(title, 1, "search_by_title");
  }, 750);

  return {
    parseTitle,
    pmids,
    loading,
  };
};

export const useNaviTrending = ({
  page,
  size,
}: {
  page?: number;
  size?: number;
}) => {
  const setNaviTrending = useSetRecoilState(naviTrendingState);
  const { data, loading } = useQuery<
    {
      naviTrending: {
        results: {
          content: SearchContentListItem;
          journal: SearchContentListItem["journal"];
        }[];
        count: number;
      };
    },
    { size?: number; page?: number }
  >(NAVI_TRENDING, { variables: { page, size } });

  useMemo(() => {
    if (data) {
      const papers = data?.naviTrending?.results
        ?.filter((e) => e)
        .map((paper) => ({
          ...paper.content,
          journal: paper.journal,
        }));
      setNaviTrending(papers);
    }
  }, [data]);

  const router = useRouter();

  const changePage = async ({
    page: _page,
    size: _size,
  }: {
    page?: number;
    size?: number;
  }) => {
    await router.push(
      `${router.pathname}?page=${_page ?? page}&size=${_size ?? size}`
    );
  };

  return {
    changePage,
    loading,
  };
};
