import { gql } from "apollo-boost";

export const GQL_TRAVERSE_PAPER = gql`
  query($after: Int) {
    traversePapers(after: $after) {
      id
      content {
        articleTitle
        medlineJournalInfo {
          medlineTA
        }
        journalInfo {
          pubdate
        }
        authors {
          foreName
          lastName
        }
        abstract {
          text
          label
        }
        articleDate
      }
      journal {
        nImpactFactor
      }
    }
  }
`;

export const GQL_REMOVE_SUB_TO = gql`
  mutation($naviSubscriberId: String!) {
    removeSubTo(naviSubscriberId: $naviSubscriberId)
  }
`;

export const GQL_GET_SUBSCRIPTIONS = gql`
  query($gsd: GetSubsDto!) {
    getSubscriptions(getSubsDto: $gsd) {
      count
      results {
        name
        description
        id
        updated_at
        owner {
          id
          username
          avatarUrl
        }
        naviId
        subscribed
        navi {
          params {
            term
          }
        }
      }
    }
  }
`;

export const GQL_GET_SUBSCRIPTION = gql`
  query($id: String!) {
    getSubscription(id: $id) {
      id
      name
      description
      updated_at
      owner {
        id
        avatarUrl
        username
      }
      jobResults {
        date
        results {
          id
          paperContents {
            id
            content {
              articleTitle
              authors {
                lastName
                foreName
                initials
                affiliationInfo
              }
              keywords
              abstract {
                text
                label
              }
              journalInfo {
                issn
                volume
                issn
                pubdate
                title
                issoAbbrTitle
              }
              pagination
              eDoi
              ePmid
              language
              articleDate
              medlineJournalInfo {
                country
                medlineTA
                nlmid
                issn
              }
              pubHistory {
                status
                date
              }
              publicationTypes
              articleIds {
                idType
                id
              }
            }
            journal {
              id
              title
              nImpactFactor
              publisherName
              country
              categories
              meshes {
                name
              }
            }
          }
        }
      }
      public
      subscribed
      subscribedTimes
      naviId
      navi {
        params {
          term
          ifFilter
          ifFilterMin
          ifFilterMax
          sort
          mindate
          maxdate
          articleTypeFilterList
        }
      }
    }
  }
`;

export const GQL_ADD_SUB_TO = gql`
  mutation($sub: AddSubToDto!) {
    addSubTo(addSubToDto: $sub)
  }
`;

export const GQL_UPDATE_SUB_PRIVACY = gql`
  mutation($p: Boolean!, $id: String!) {
    updateSubPrivacy(id: $id, public: $p)
  }
`;

export const GQL_ADD_COLLECTION = gql`
  mutation($ado: AddCollectionDto!) {
    addCollection(addCollectionDto: $ado) {
      id
    }
  }
`;

export const GQL_GET_COLLECTIONS = gql`
  query(
    $skip: Int
    $take: Int
    $field: collectionPaginationOrderField
    $ordered: Order
  ) {
    getCollections(take: $take, skip: $skip, field: $field, ordered: $ordered) {
      count
      results {
        id
        updated_at
        updatedTime
        name
        description
        size
        owner {
          id
          username
          avatarUrl
        }
        paperIds
      }
    }
  }
`;

export const GQL_GET_MY_COLLECTIONS = gql`
  query($take: Int, $skip: Int) {
    getMyCollections(skip: $skip, take: $take) {
      count
      results {
        id
        updatedTime
        name
        description
        size
      }
    }
  }
`;

export const GQL_ALTER_MY_COLLECTION = gql`
  mutation($acd: AlterCollectionDto!) {
    alterCollection(alterCollectionDto: $acd) {
      id
    }
  }
`;

export const GQL_GET_COLLECTION = gql`
  query($id: String!, $take: Int, $skip: Int) {
    getCollection(id: $id, take: $take, skip: $skip) {
      id
      size
      updated_at
      name
      description
      public
      owner {
        id
        username
        avatarUrl
      }
      papers {
        id
        content {
          articleTitle
          authors {
            lastName
            foreName
            initials
            affiliationInfo
          }
          keywords
          abstract {
            text
            label
          }
          journalInfo {
            issn
            volume
            issn
            pubdate
            title
            issoAbbrTitle
          }
          pagination
          eDoi
          ePmid
          language
          articleDate
          medlineJournalInfo {
            country
            medlineTA
            nlmid
            issn
          }
          pubHistory {
            status
            date
          }
          publicationTypes
          articleIds {
            idType
            id
          }
        }
        journal {
          id
          title
          nImpactFactor
          publisherName
          country
          categories
          meshes {
            name
          }
        }
      }
    }
  }
`;

export const GQL_UPDATE_COLLECTION = gql`
  mutation($ucd: UpdateCollectionDto!) {
    updateCollection(updateCollectionDto: $ucd) {
      id
      size
      updated_at
      name
      description
      public
      owner {
        id
        username
        avatarUrl
      }
      papers {
        id
        content {
          articleTitle
          authors {
            lastName
            foreName
            initials
            affiliationInfo
          }
          keywords
          abstract {
            text
            label
          }
          journalInfo {
            issn
            volume
            issn
            pubdate
            title
            issoAbbrTitle
          }
          pagination
          eDoi
          ePmid
          language
          articleDate
          medlineJournalInfo {
            country
            medlineTA
            nlmid
            issn
          }
          pubHistory {
            status
            date
          }
          publicationTypes
          articleIds {
            idType
            id
          }
        }
        journal {
          id
          title
          nImpactFactor
          publisherName
          country
          categories
          meshes {
            name
          }
        }
      }
    }
  }
`;

export const GQL_REMOVE_COLLECTION = gql`
  mutation($id: String!) {
    removeCollection(id: $id)
  }
`;

export const GQL_UPDATE_SUBSCRIPTION = gql`
  mutation($usd: UpdateSubscribeDto!) {
    updateSubscriber(updateSubscribeDto: $usd) {
      id
      updated_at
      name
      description
      public
    }
  }
`;
