import { gql } from "apollo-boost";

export const PUBMED_CITATION_BUNDLE = gql`
  query($doi: String!) {
    getCitationBundle(doi: $doi) {
      format
      result
    }
  }
`;

export const PUBMED_DOWNLOAD = gql`
  query($doi: String!) {
    eDownload(doi: $doi) {
      oa_locations {
        url
        url_for_pdf
        url_for_landing_page
        repository_institution
      }
    }
  }
`;

export const PUBMED_RIS = gql`
  query($doi: String!) {
    getRIS(doi: $doi)
  }
`;

export const UPDATE_DISPLAY_PREFERENCE = gql`
  mutation($update: UpdateDisplayPreferenceDto!) {
    updateDisplayPreference(updateDisplayPreferenceDto: $update) {
      display {
        defaultActiveKey
        immersiveMode
        size
        verbose
      }
    }
  }
`;

export const GET_DISPLAY_PREFERENCE = gql`
  query {
    getPreference {
      display {
        immersiveMode
        verbose
        defaultActiveKey
        size
      }
    }
  }
`;

export const PUBMED_ALL = gql`
  query($eSearch: ESearchDto!, $id: String!) {
    pubmedAll(eSearch: $eSearch, id: $id) {
      db
      term
      datetype
      mindate
      maxdate
      sort
      ifFilter
      ifFilterMin
      ifFilterMax
      dateFilter
      articleTypeFilterList
      id
      pubmedSearch {
        count
        querytranslation
        query_key
        webenv
        retstart
        retmax
        papers {
          articleTitle
          authors {
            lastName
            foreName
            initials
            affiliationInfo
          }
          keywords
          abstract {
            text
            label
          }
          journalInfo {
            issn
            volume
            issn
            pubdate
            title
            issoAbbrTitle
          }
          pagination
          eDoi
          ePmid
          language
          articleDate
          medlineJournalInfo {
            country
            medlineTA
            nlmid
            issn
          }
          pubHistory {
            status
            date
          }
          publicationTypes
          articleIds {
            idType
            id
          }
          journal {
            id
            title
            nImpactFactor
            publisherName
            country
            categories
            meshes {
              name
            }
          }
        }
      }
    }
  }
`;

export const PUBMED_FETCH = gql`
  query($eFetch: EFetchDto!) {
    pubmedSearch(eFetch: $eFetch) {
      papers {
        articleTitle
        authors {
          lastName
          foreName
          initials
          affiliationInfo
        }
        keywords
        abstract {
          text
          label
        }
        journalInfo {
          issn
          volume
          issn
          pubdate
          title
          issoAbbrTitle
        }
        pagination
        eDoi
        ePmid
        language
        articleDate
        medlineJournalInfo {
          country
          medlineTA
          nlmid
          issn
        }
        pubHistory {
          status
          date
        }
        publicationTypes
        articleIds {
          idType
          id
        }
        journal {
          id
          title
          nImpactFactor
          publisherName
          country
          categories
          meshes {
            name
          }
        }
      }
    }
  }
`;

export const PUBMED_SEARCH_HISTORY = gql`
  query($skip: Int, $take: Int) {
    getMyPubmedHistories(skip: $skip, take: $take) {
      count
      results {
        updated_at
        id
        params {
          term
          datetype
          mindate
          maxdate
          sort
          ifFilter
          dateFilter
          ifFilterMin
          ifFilterMax
          articleTypeFilterList
        }
      }
    }
  }
`;

export const PUBMED_ADD_HISTORY = gql`
  mutation($addPubmedHistoryDto: AddPubmedHistoryDto!) {
    addPubmedHistory(addPubmedHistoryDto: $addPubmedHistoryDto) {
      id
      params {
        db
        term
        datetype
        mindate
        maxdate
        sort
        ifFilter
        ifFilterMin
        ifFilterMax
        dateFilter
        articleTypeFilterList
      }
    }
  }
`;

export const PUBMED_PARSE_DOI = gql`
  query($doi: String!) {
    parseDoi(doi: $doi)
  }
`;
