import { DetailedPaperItem } from "./paper.interface";
import { SearchArticleType, SearchContentListItem } from "./pubmed.interface";

export enum NaviPaperSort {
  ARTICLE_DATE = "ARTICLE_DATE",
  MOST_RELEVANT = "MOST_RELEVANT",
  IMPACT_FACTOR = "IMPACT_FACTOR",
}

export interface NaviParams {
  term: string;
  ifFilter: boolean;
  ifFilterMin: number;
  ifFilterMax: number;
  sort: NaviPaperSort;
  mindate?: string;
  maxdate?: string;
  articleTypeFilterList: SearchArticleType[];
  page: number;
  size: number;
}

export interface RecentHistory {
  id: string;
  params: NaviParams;
}

export interface INaviEachHistory extends RecentHistory {
  updated_at: string;
}

export interface INaviHistory {
  count: number;
  results: INaviEachHistory[];
}

export interface DisplayHelper {
  count: number;
}

export interface NaviState {
  id: string;
  searchParams: NaviParams;
  recentHistory: RecentHistory[];
  fetchResults: SearchContentListItem[];
  displayHelper: DisplayHelper;
  suggestTerms: string[];
}

export interface NaviDocs {
  id: string;
  paperContents: {
    content: DetailedPaperItem;
    journal?: DetailedPaperItem["journal"];
  };
}

export interface NaviInspection {
  id: string;
  searchParams: Partial<NaviParams>;
  took: number;
  count: number;
  naviAggsImpactFactorQuantiles: {
    key: number;
    value: number;
  }[];
  naviAggsTopLocations: {
    key: string;
    score: number;
    count: number;
    papers: NaviDocs[];
  }[];
  naviAggsTopAffiliations: {
    key: string;
    score: number;
    count: number;
    papers: NaviDocs[];
  }[];
  naviAggsTopAuthors: {
    key: string;
    score: number;
    count: number;
    papers: NaviDocs[];
  }[];
  naviAggsTopJournals: {
    from: number | null;
    to: number;
    count: number;
    journals: { title: string; count: number; papers: NaviDocs[] }[];
  }[];
  naviAggsDateImpactFactor: {
    year: string;
    count: number;
    ratio: { from: number | null; to: number; count: number }[];
  }[];
  naviAggsSignificantKeywords: {
    keyword: string;
    count: number;
    score: number;
  }[];
}

export interface ISubHistory {
  count: number;
  results: {
    navi: { params: NaviParams };
    updated_at: string;
    id?: string;
    public?: boolean;
  }[];
}
