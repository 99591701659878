import useTranslation from "@/hooks/useTranslation";
import { currentUserState, routerState } from "@/stores/common";
import Button from "@material-ui/core/Button";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import { Alert } from "antd";
import { useRouter } from "next/router";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styles from "./styles.module.less";

const Message = () => {
  const { t } = useTranslation("common");
  const router = useRouter();
  const setRouterState = useSetRecoilState(routerState);

  return (
    <div className={styles.flex}>
      <NotificationsActiveOutlinedIcon />
      <div className={styles.message}> {t("notification-bar.message4")}</div>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => {
          setRouterState({ redirect: router.asPath });
          router.push("/auth/register");
        }}
      >
        {t("notification-bar.button")}
      </Button>
    </div>
  );
};

const NotificationBar = () => {
  const currentUser = useRecoilValue(currentUserState);
  return (
    <div>
      {!currentUser.id && (
        <Alert
          message={<Message />}
          banner
          closable
          showIcon={false}
          className={styles.notificationBar}
        />
      )}
    </div>
  );
};

export default NotificationBar;
