import avatarIcon from "@/assets/images/svg/avatar.svg";
import { v4 as uuidv4 } from "uuid";
import { isClient, isServer } from "./common.util";
import { getItem, setItem } from "./cookies.util";
import { shortUUID } from "./string.util";

export const modifyAvatarUrl = (avatarUrl?: string) => {
  const hash = getItem("avatarHash");
  if (avatarUrl) {
    if (isServer) return avatarUrl + `&_d=${shortUUID(uuidv4())}`;
    if (avatarUrl && hash) return avatarUrl + `&_d=${hash}`;
    if (!hash && isClient) {
      setItem("avatarHash", shortUUID(uuidv4()));
    }
    return avatarUrl;
  }
  return avatarIcon;
};
