import {
  defaultDisplayHelper,
  defaultDisplayPreference,
  defaultFetchParams,
  defaultSearchParams,
} from "@/helpers/defaultValue";
import { SearchState } from "@/interfaces/pubmed.interface";
import _ from "lodash";
import { atom, RecoilState, selector, selectorFamily } from "recoil";
import { v4 as uuidv4 } from "uuid";

export const defaultPubmedState = {
  id: uuidv4(),
  displayHelper: defaultDisplayHelper,
  searchParams: defaultSearchParams,
  fetchParams: defaultFetchParams,
  displayPreference: defaultDisplayPreference,
  recentHistory: [],
  fetchResults: [],
  suggestTerms: [],
  translator: {},
  translation: {},
};

export const pubmedState: RecoilState<SearchState> = atom({
  key: "pubmed",
  default: defaultPubmedState,
});

export const pubmedAcState: RecoilState<string[]> = atom({
  key: "pubmedAc",
  default: [],
});

type PubmedMergeField =
  | "displayHelper"
  | "searchParams"
  | "fetchParams"
  | "displayPreference"
  | "translation"
  | "translator";

export const pubmedMergeFieldState = selectorFamily<any, PubmedMergeField>({
  key: "pubmedMergeField",
  get: (field: PubmedMergeField) => ({ get }) => get(pubmedState)[field],
  set: (field: PubmedMergeField) => ({ set }, newValue) =>
    set(pubmedState, (prevState) => {
      // @ts-ignore
      const { __typename, ...others } = newValue;
      return {
        ...prevState,
        [field]: { ...prevState[field], ...others },
      };
    }),
});

export const pubmedReplaceFieldState = selectorFamily<any, keyof SearchState>({
  key: "pubmedReplaceField",
  get: (field: keyof SearchState) => ({ get }) => get(pubmedState)[field],
  set: (field: keyof SearchState) => (
    { set },
    newValue: SearchState[typeof field]
  ) => set(pubmedState, (prevState) => ({ ...prevState, [field]: newValue })),
});

export const pubmedComputedFieldState = selector({
  key: "pubmedComputedField",
  get: ({ get }) => {
    const latest10HistoryTerms = _.uniqBy(
      get(pubmedState)
        ?.recentHistory?.map(({ params }) => params?.term)
        .filter((e) => e)
        .map((term) => ({ value: term! }))
        .reverse(),
      "value"
    ).slice(0, 10);
    return {
      latest10HistoryTerms,
      filteredHistory: get(pubmedState)?.recentHistory?.filter(
        (e) => e?.params?.term
      ),
    };
  },
});
