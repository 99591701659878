import CollectionList from "@/components/IndexPage/CollectionList";
import SubscriptionsList from "@/components/IndexPage/SubscriptionsList";
import RouteCard from "@/components/RouteCard";
import SearchCard from "@/components/SearchCard";
import { useNaviParseTitle } from "@/hooks/useNavi";
import { usePubmedParseDoi } from "@/hooks/usePubmed";
import useTranslation from "@/hooks/useTranslation";
import CommonLayout from "@/layouts/Common";
import { currentUserState } from "@/stores/common";
import { Col, Row } from "antd";
import _ from "lodash";
import { NextPage } from "next";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import styles from "./index.module.less";

const Index: NextPage = () => {
  const { t } = useTranslation("menu");
  const menus1 = [
    {
      icon: "navi",
      title: t("explore.navi"),
      description: t("explore.navi-info"),
      shortUrl: "https://n.medicgo.org",
      url: "/explore/navi",
    },
    {
      icon: "pubmed",
      title: t("explore.pubmed"),
      description: t("explore.pubmed-info"),
      shortUrl: "https://p.medicgo.org",
      url: "/explore/pubmed",
    },
    {
      icon: "journal",
      title: t("explore.journal"),
      description: t("explore.journal-info"),
      shortUrl: "https://j.medicgo.org",
      url: "/explore/journal",
    },
  ];
  const menus2: {
    icon: string;
    title: string;
    description: string;
    url: string;
    size: "small" | "large";
  }[] = [
    {
      icon: "subscription",
      title: t("bundle.subscription"),
      description: t("bundle.subscription-info"),
      url: "/bundle/subscription",
      size: "small",
    },
    {
      icon: "collection",
      title: t("bundle.collection"),
      description: t("bundle.collection-info"),
      url: "/bundle/collection",
      size: "small",
    },
    {
      icon: "chat",
      title: t("about.chat"),
      description: t("about.chat-info"),
      url: "/about/chat",
      size: "small",
    },
    {
      icon: "docs",
      title: t("about.docs"),
      description: t("about.docs-info"),
      url: "/about/docs",
      size: "small",
    },
  ];

  const { parseDoi, pmids: dPmids, loading: dLoading } = usePubmedParseDoi();

  const { parseTitle, pmids: tPmids, loading: tLoading } = useNaviParseTitle();

  const currentUser = useRecoilValue(currentUserState);

  const [pPmids, setPPmids] = useState<{ pmid: string }[]>([]);
  const parsePmid = _.debounce((pmid: string) => {
    if (!Number.isNaN(Number(pmid))) setPPmids([{ pmid }]);
  }, 500);

  const searchs = [
    {
      title: t("common:index.title.itself"),
      placeholder: t("common:index.title.placeholder"),
      shortUrl: "https://medicgo.org/t/<title>",
      options: tPmids,
      handleAc: parseTitle,
      loading: tLoading,
    },
    {
      title: t("common:index.doi.itself"),
      placeholder: t("common:index.doi.placeholder"),
      shortUrl: "https://medicgo.org/d/<doi>",
      options: dPmids,
      handleAc: parseDoi,
      loading: dLoading,
    },
    {
      title: t("common:index.pmid.itself"),
      placeholder: t("common:index.pmid.placeholder"),
      shortUrl: "https://medicgo.org/p/<pmid>",
      options: pPmids,
      handleAc: parsePmid,
      loading: false,
    },
  ];

  return (
    <CommonLayout>
      <div className={styles.wrapper}>
        <Row gutter={{ xs: 16, sm: 36 }}>
          {menus1.map((menu) => (
            <Col md={8} span={24} key={menu.url} className={styles.container}>
              <RouteCard {...menu} />
            </Col>
          ))}
        </Row>
        <Row gutter={{ xs: 16, sm: 36 }}>
          {searchs.map((search) => (
            <Col
              md={8}
              span={24}
              key={search.title}
              className={styles.container}
            >
              <SearchCard {...search} />
            </Col>
          ))}
        </Row>
        <Row gutter={{ xs: 16, sm: 36 }}>
          {menus2.map((menu) => (
            <Col
              md={6}
              sm={12}
              span={24}
              key={menu.url}
              className={styles.container}
            >
              <RouteCard {...menu} />
            </Col>
          ))}
        </Row>
        <div className={styles.list}>
          <CollectionList />
        </div>
        {currentUser?.id && (
          <div className={styles.list}>
            <SubscriptionsList />
          </div>
        )}
      </div>
    </CommonLayout>
  );
};

Index.getInitialProps = async () => {
  return {
    namespacesRequired: ["common", "menu"],
  };
};

export default Index;
