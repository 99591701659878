export const parseFetchParams = ({
  page = 1,
  size = 20,
}: {
  page?: number;
  size?: number;
}) => ({
  retmax: size,
  retstart: (page - 1) * size,
});
