import { copy } from "@/utils/common.util";
import { Card, CardContent, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import ShortTextIcon from "@material-ui/icons/ShortText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRouter } from "next/router";
import { useState } from "react";
import styles from "./styles.module.less";

interface IProps {
  title: string;
  placeholder: string;
  shortUrl: string;
  loading: boolean;
  options: { pmid: string }[];
  handleAc: (s: string) => void;
  onChange?: (e, value: string) => void;
  className?: string;
  variant?: "outlined" | "standard" | "filled";
}

const SearchCard: React.FC<IProps> = ({
  title,
  placeholder,
  shortUrl,
  loading,
  options = [],
  handleAc,
  onChange,
  className,
  variant = "standard",
}) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();

  return (
    <Card className={`${className ?? styles.box}`} elevation={0}>
      <CardContent>
        <div>
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            freeSolo
            options={options.map((option) => option?.pmid)}
            filterOptions={(options) => options}
            loading={loading}
            onInputChange={(e, value) => handleAc(value)}
            onChange={
              onChange ??
              ((e, value) =>
                router.push(
                  "/navigator/paper/[id]",
                  `/navigator/paper/${value}`
                ))
            }
            renderInput={(params) => (
              <TextField
                multiline
                {...params}
                label={title}
                variant={variant}
                helperText={placeholder}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <Typography
          variant="body2"
          color="textSecondary"
          component="div"
          className={styles.boxer}
          onClick={() => copy(shortUrl?.replace(/<.*?>/, ""))}
        >
          <div className={styles.flex}>
            <ShortTextIcon />
            <div className={styles.url}>{shortUrl}</div>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SearchCard;
