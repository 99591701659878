import DynamicAvatar from "@/components/MyAvatar/DynamicAvatar";
import { GQL_GET_COLLECTIONS } from "@/graphql/bundle.graphql";
import useTranslation from "@/hooks/useTranslation";
import {
  ICollection,
  ICollectionOrderField,
} from "@/interfaces/bundle.interface";
import { Order } from "@/interfaces/common.interface";
import { modifyAvatarUrl } from "@/utils/avatar.util";
import { shortUUID } from "@/utils/string.util";
import { formatRelativeTime } from "@/utils/time.util";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import { List, Tooltip } from "antd";
import Link from "next/link";
import { useState } from "react";
import styles from "./styles.module.less";

const CollectionList: React.FC = () => {
  const [{ page, size }, setPageSize] = useState({ page: 1, size: 5 });

  const { data, loading } = useQuery<
    { getCollections: { count: number; results: ICollection[] } },
    {
      skip?: number;
      take?: number;
      field?: ICollectionOrderField;
      ordered?: Order;
    }
  >(GQL_GET_COLLECTIONS, {
    variables: { skip: (page - 1) * size, take: size },
  });

  const { t } = useTranslation("common");
  return (
    <List
      className={styles.container}
      loading={loading}
      itemLayout="vertical"
      size="large"
      header={
        <div>
          <Typography gutterBottom variant="h6" component="div">
            {t("index.collection.itself")}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="div">
            {t("index.collection.description")}
          </Typography>
        </div>
      }
      pagination={{
        total: data?.getCollections.count,
        pageSize: size,
        current: page,
        hideOnSinglePage: true,
        onChange: (page, pageSize) => {
          setPageSize({ page, size: pageSize });
        },
        showSizeChanger: true,
      }}
      dataSource={data?.getCollections.results}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            avatar={
              <Tooltip title={item.owner.username}>
                <DynamicAvatar
                  src={modifyAvatarUrl(item.owner.avatarUrl)}
                  size="large"
                />
              </Tooltip>
            }
            title={
              <Link
                href="/bundle/collection/[id]"
                as={`/bundle/collection/${shortUUID(item?.id)}`}
              >
                <a className={styles.url}>{item?.name}</a>
              </Link>
            }
            description={
              <div className={styles.flex}>
                <div>{item?.size}</div>
                <div>{formatRelativeTime(item?.updatedTime)}</div>
              </div>
            }
          />
        </List.Item>
      )}
    />
  );
};

export default CollectionList;
