import Banner from "@/components/Banner";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Loader from "@/components/Loader";
import NotificationBar from "@/components/NotificationBar";
import { TIMEOUT } from "@/constants/env";
import { statsEveryPage } from "@/hooks/useMatomo";
import { currentUserState } from "@/stores/common";
import { PageTransition } from "next-page-transitions";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import styles from "./styles.module.less";

const DocsBar = dynamic(() => import("@/components/DocsBar"), { ssr: false });

const CommonLayout: React.FC = (props) => {
  const { children } = props;
  const router = useRouter();
  const currentUser = useRecoilValue(currentUserState);

  useEffect(() => {
    const timer = setTimeout(() => {
      statsEveryPage(location.href, currentUser?.username);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [router.asPath]);

  return (
    <>
      <NotificationBar />
      <Header />
      <Banner />
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <PageTransition
            timeout={TIMEOUT}
            classNames="page-transition"
            loadingComponent={<Loader />}
            loadingDelay={500}
            loadingTimeout={{
              enter: TIMEOUT,
              exit: 0,
            }}
            loadingClassNames="loading-indicator"
          >
            <React.Fragment key={router.route}>{children}</React.Fragment>
          </PageTransition>
          <DocsBar />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CommonLayout;
