import DynamicAvatar from "@/components/MyAvatar/DynamicAvatar";
import { GQL_GET_SUBSCRIPTIONS } from "@/graphql/bundle.graphql";
import useTranslation from "@/hooks/useTranslation";
import { GetSubsDtoField, ISubscriptions } from "@/interfaces/bundle.interface";
import { Order } from "@/interfaces/common.interface";
import { modifyAvatarUrl } from "@/utils/avatar.util";
import { shortUUID } from "@/utils/string.util";
import { formatRelativeTime } from "@/utils/time.util";
import { useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import { List, Tooltip } from "antd";
import Link from "next/link";
import { useState } from "react";
import styles from "./styles.module.less";

const SubscriptionsList: React.FC = () => {
  const [{ page, size }, setPageSize] = useState({ page: 1, size: 5 });

  const { data, loading } = useQuery<
    { getSubscriptions: ISubscriptions },
    {
      gsd: {
        take: number;
        skip: number;
        order?: Order;
        field?: GetSubsDtoField;
      };
    }
  >(GQL_GET_SUBSCRIPTIONS, {
    variables: {
      gsd: {
        skip: (page - 1) * size,
        take: size,
        order: Order.DESC,
        field: GetSubsDtoField.UPDATED_AT,
      },
    },
  });

  const { t } = useTranslation("common");

  return (
    <List
      className={styles.container}
      loading={loading}
      itemLayout="vertical"
      size="large"
      header={
        <div>
          <Typography gutterBottom variant="h6" component="div">
            {t("index.subscription.itself")}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="div">
            {t("index.subscription.description")}
          </Typography>
        </div>
      }
      pagination={{
        total: data?.getSubscriptions.count,
        pageSize: size,
        current: page,
        hideOnSinglePage: true,
        onChange: (page, pageSize) => {
          setPageSize({ page, size: pageSize });
        },
        showSizeChanger: true,
      }}
      dataSource={data?.getSubscriptions.results}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            avatar={
              <Tooltip title={item.owner.username}>
                <DynamicAvatar
                  src={modifyAvatarUrl(item.owner.avatarUrl)}
                  size="large"
                />
              </Tooltip>
            }
            title={
              <Link
                href="/bundle/subscription/[id]"
                as={`/bundle/subscription/${shortUUID(item?.id)}`}
              >
                <a className={styles.url}>
                  {item?.name || item?.navi?.params?.term}
                </a>
              </Link>
            }
            description={formatRelativeTime(item?.updated_at)}
          />
        </List.Item>
      )}
    />
  );
};

export default SubscriptionsList;
