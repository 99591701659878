import { NaviPaperSort } from "@/interfaces/navi.interface";
import { SearchSortType } from "@/interfaces/pubmed.interface";
import { getItem } from "@/utils/cookies.util";

export const defaultSearchParams = {
  term: "",
  sort: (getItem("pubmed_sort") as SearchSortType) || SearchSortType.RELEVANCE,
  ifFilter: false,
  dateFilter: false,
  ifFilterMax: 30,
  ifFilterMin: 20,
  articleTypeFilterList: [],
  retstart: 0,
  retmax: 20,
};

export const defaultFetchParams = {
  query_key: 1,
  webenv: "",
  retstart: 0,
  retmax: 20,
};

export const defaultDisplayHelper = {
  error: false,
  count: 0,
  queryTranslation: "",
  correctedQuery: "",
  page: 1,
  size: 20,
  translator: {},
  translation: [],
};

export const defaultDisplayPreference = {
  verbose: false,
  defaultActiveKey: "Helper",
  immersiveMode: false,
  size: 20,
};

export const defaultNaviParams = {
  term: "",
  sort: (getItem("navi_sort") as NaviPaperSort) || NaviPaperSort.MOST_RELEVANT,
  ifFilter: false,
  ifFilterMax: 30,
  ifFilterMin: 20,
  articleTypeFilterList: [],
  page: 1,
  size: 20,
};
