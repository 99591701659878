import { NaviDocs, NaviParams } from "./navi.interface";
import { DetailedPaperItem } from "./paper.interface";
import { IPublicUserInfo } from "./user.interface";

export interface ISubscription {
  id: string;
  created_at: string;
  updated_at: string;
  public?: boolean;
  subscribed: boolean;
  naviId: string;
  name: string;
  description: string;
  owner: IPublicUserInfo;
  subscribedTimes: number;
  jobResults: {
    date: string;
    results: NaviDocs[];
  }[];
  navi: {
    params: NaviParams;
  };
}

export interface ISubscriptions {
  count: number;
  results: ISubscription[];
}

export enum GetSubsDtoField {
  UPDATED_AT = "UPDATED_AT",
  SUBSCRIBED_TIMES = "SUBSCRIBED_TIMES",
}

export interface ICollection {
  id: string;
  size: number;
  updated_at: string;
  name: string;
  description: string;
  owner: IPublicUserInfo;
  paperIds: string[];
  public: boolean;
  viewedTimes: number;
  updatedTime: string;
  papers: {
    id: string;
    content: DetailedPaperItem;
    journal: DetailedPaperItem["journal"];
  }[];
}

export enum ICollectionOrderField {
  VIEWED_TIMES = "VIEWED_TIMES",
  UPDATED_TIME = "UPDATED_TIME",
}

export enum IAlterCollectionAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

export interface IBatchPaperToCollection {
  id: string;
  content: {
    articleTitle: string;
    journalInfo: {
      pubdate: string;
    };
  };
}
